import React, { useState } from "react";
import InstagramProfile from "./InstagramProfile";

export default function RegisterForm() {
  const [instagramHandle, setInstagramHandle] = useState("");
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50">
      <div className="w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg">
        <div className="text-center">
          <img
            alt="PR Hermanns"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Registriere dich für unsere Influencer Datenbank
          </h2>
        </div>

        <form action="#" method="POST" className="mt-8 space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
            {/* Anrede Dropdown */}
            <div>
              <label
                htmlFor="salutation"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Anrede
              </label>
              <select
                id="salutation"
                name="salutation"
                required
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              >
                <option>Herr</option>
                <option>Frau</option>
                <option>Divers</option>
              </select>
            </div>

            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email Adresse
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                autoComplete="email"
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            {/* Vorname */}
            <div>
              <label
                htmlFor="firstname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Vorname
              </label>
              <input
                id="firstname"
                name="firstname"
                type="text"
                required
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            {/* Nachname */}
            <div>
              <label
                htmlFor="lastname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nachname
              </label>
              <input
                id="lastname"
                name="lastname"
                type="text"
                required
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            {/* Geburtsdatum */}
            <div>
              <label
                htmlFor="birthdate"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Geburtsdatum
              </label>
              <input
                id="birthdate"
                name="birthdate"
                type="date"
                required
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            {/* Straße und Hausnummer */}
            <div className="sm:col-span-2">
              <label
                htmlFor="street"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Straße und Hausnummer
              </label>
              <input
                id="street"
                name="street"
                type="text"
                required
                autoComplete="street-address"
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            {/* Postleitzahl */}
            <div className="sm:col-span-2">
              <label
                htmlFor="postalcode"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Postleitzahl
              </label>
              <input
                id="postalcode"
                name="postalcode"
                type="text"
                required
                autoComplete="postal-code"
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            {/* Instagram */}
            <div>
              <label
                htmlFor="instagram"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Instagram Handle
              </label>
              <input
                id="instagram"
                name="instagram"
                type="text"
                value={instagramHandle}
                onChange={(e) => setInstagramHandle(e.target.value)}
                placeholder="@username"
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            {/* Zeige das Instagram-Profil nur, wenn ein Handle eingegeben wurde */}
            {instagramHandle && (
              <InstagramProfile instagramHandle={instagramHandle} />
            )}

            {/* Weitere Formularfelder und der Submit-Button */}

            {/* TikTok */}
            <div>
              <label
                htmlFor="tiktok"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                TikTok Handle
              </label>
              <input
                id="tiktok"
                name="tiktok"
                type="text"
                required
                autoComplete="username"
                placeholder="@username"
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Kategorien
              </label>
              <select
                id="category"
                name="category"
                required
                className="mt-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
              >
                <option>Beauty</option>
                <option>Make-Up</option>
                <option>Fashion</option>
              </select>
            </div>

            {/* Datenschutz Checkbox */}
            <div className="sm:col-span-2 flex items-center">
              <input
                id="privacy"
                name="privacy"
                type="checkbox"
                required
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              />
              <label
                htmlFor="privacy"
                className="ml-2 block text-sm text-gray-900"
              >
                Ich habe die{" "}
                <a href="#" className="text-primary hover:text-primaryHover">
                  Datenschutzbestimmungen
                </a>{" "}
                gelesen und verstanden.
              </label>
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primary hover:bg-primaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primaryFocus px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
            >
              Registrieren
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
