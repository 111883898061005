import React, { useState, useEffect } from "react";

export default function InstagramProfile({ instagramHandle }) {
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    async function fetchProfileData() {
      try {
        console.log("Fetching data for:", instagramHandle); // Logge den Instagram Handle
        const response = await fetch(
          `http://localhost:5000/api/instagram?handle=${instagramHandle}`
        );
        console.log("Response status:", response.status); // Logge den Status der Antwort

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          console.log("Fetched data:", data); // Logge die erhaltenen Daten

          if (data.followers >= 5000) {
            setProfileData(data);
          } else {
            setProfileData({ error: "Zu wenige Follower." });
          }
        } else {
          const text = await response.text();
          console.error("Unerwartete Antwort:", text);
          setProfileData({ error: "Unerwartete Antwort vom Server." });
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Instagram-Profildaten:", error);
      }
    }

    if (instagramHandle) {
      fetchProfileData();
    }
  }, [instagramHandle]);

  if (!profileData) {
    return <div>Lädt...</div>;
  }

  return profileData.error ? (
    <div className="text-red-500">{profileData.error}</div>
  ) : (
    <div className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md">
      <img
        className="h-16 w-16 rounded-full"
        src={profileData.profile_picture}
        alt={`${profileData.username}'s profile`}
      />
      <div>
        <div className="text-xl font-bold text-gray-900">
          {profileData.username}
        </div>
        <div className="text-sm text-gray-500">
          {profileData.followers} Follower{" "}
          {/* Direkt auf profileData zugreifen */}
        </div>
      </div>
    </div>
  );
}
