import React from "react";

export default function Settings() {
  return (
    <div>
      <h2 className="text-2xl font-bold">Settings</h2>
      <p className="mt-4 text-gray-600">
        Hier kannst du deine Einstellungen anpassen.
      </p>
    </div>
  );
}
