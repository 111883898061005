import React from "react";
import { Link, Outlet } from "react-router-dom";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";

export default function Layout() {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          {/* Flex container to ensure the logo and menu items are properly aligned */}
          <div className="flex items-center space-x-8 w-full">
            {/* Logo on the left */}
            <div className="flex-shrink-0">
              <img
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Logo"
                className="h-8 w-auto"
              />
            </div>

            {/* Center the menu items */}
            <div className="flex-1 flex justify-center">
              <div className="flex space-x-8">
                <Link
                  to="/admin/dashboard"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Dashboard
                </Link>
                <Link
                  to="/admin/influencer"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Influencer
                </Link>
                <Link
                  to="/admin/settings"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Settings
                </Link>
              </div>
            </div>

            {/* Right side (logout icon) */}
            <div className="flex items-center space-x-4">
              <Link to="/logout">
                <ArrowLeftOnRectangleIcon className="h-8 w-8 text-gray-600 hover:text-gray-900" />
              </Link>
            </div>
          </div>
        </div>
      </header>

      <main className="py-10">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {/* Platz für die Inhalte der Seiten, die durch die Navigation geladen werden */}
          <Outlet />
        </div>
      </main>
    </div>
  );
}
