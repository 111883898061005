import React, { useState } from "react";
import { influencers } from "./data"; // Importiere die Beispiel-Daten

export default function Influencer() {
  const [selectedIds, setSelectedIds] = useState([]);

  // Funktion zum Auswählen oder Deselektieren aller Zeilen
  const toggleSelectAll = () => {
    if (selectedIds.length === influencers.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(influencers.map((influencer) => influencer.id));
    }
  };

  // Funktion zum Auswählen oder Deselektieren einer einzelnen Zeile
  const toggleSelectRow = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Influencer List</h2>
        <div className="space-x-2">
          <button className="bg-indigo-600 text-white px-4 py-2 rounded-md shadow hover:bg-indigo-700">
            Add User
          </button>
          <button
            className={`bg-gray-600 text-white px-4 py-2 rounded-md shadow ${
              selectedIds.length > 1
                ? "hover:bg-gray-700"
                : "opacity-50 cursor-not-allowed"
            }`}
            disabled={selectedIds.length <= 1}
          >
            Edit User
          </button>
          <button
            className={`bg-gray-600 text-white px-4 py-2 rounded-md shadow ${
              selectedIds.length > 1
                ? "hover:bg-gray-700"
                : "opacity-50 cursor-not-allowed"
            }`}
            disabled={selectedIds.length <= 1}
          >
            Export
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  checked={selectedIds.length === influencers.length}
                  onChange={toggleSelectAll}
                />
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Kontaktinformationen
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Social Media
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Adresse
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Kategorie
              </th>
              <th className="px-6 py-3 relative text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {influencers.map((influencer) => (
              <tr key={influencer.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    checked={selectedIds.includes(influencer.id)}
                    onChange={() => toggleSelectRow(influencer.id)}
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {influencer.salutation} {influencer.firstname}{" "}
                    {influencer.lastname}
                  </div>
                  <div className="text-sm text-gray-500">
                    {influencer.birthdate}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {influencer.email}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {influencer.instagram}
                  </div>
                  <div className="text-sm text-gray-500">
                    {influencer.tiktok}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {influencer.street}
                  </div>
                  <div className="text-sm text-gray-500">
                    {influencer.postalcode}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {influencer.category}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                    Edit
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
