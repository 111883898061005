// data.js
export const influencers = [
  {
    id: 1,
    salutation: "Herr",
    firstname: "John",
    lastname: "Doe",
    email: "johndoe@example.com",
    birthdate: "01.01.1990",
    street: "Musterstraße 1",
    postalcode: "12345",
    instagram: "@johndoe",
    tiktok: "@johndoetiktok",
    category: "Beauty",
  },
  {
    id: 2,
    salutation: "Frau",
    firstname: "Jane",
    lastname: "Smith",
    email: "janesmith@example.com",
    birthdate: "15.02.1985",
    street: "Beispielweg 2",
    postalcode: "54321",
    instagram: "@janesmith",
    tiktok: "@janesmithtok",
    category: "Make-Up",
  },
  {
    id: 3,
    salutation: "Herr",
    firstname: "Michael",
    lastname: "Müller",
    email: "michaelmueller@example.com",
    birthdate: "23.03.1978",
    street: "Hauptstraße 3",
    postalcode: "11111",
    instagram: "@michaelmueller",
    tiktok: "@michaelmtok",
    category: "Fashion",
  },
  {
    id: 4,
    salutation: "Frau",
    firstname: "Anna",
    lastname: "Schneider",
    email: "annaschneider@example.com",
    birthdate: "08.04.1992",
    street: "Nebenstraße 4",
    postalcode: "22222",
    instagram: "@annaschneider",
    tiktok: "@annatok",
    category: "Beauty",
  },
  {
    id: 5,
    salutation: "Divers",
    firstname: "Alex",
    lastname: "Taylor",
    email: "alextaylor@example.com",
    birthdate: "12.05.2000",
    street: "Ringstraße 5",
    postalcode: "33333",
    instagram: "@alextaylor",
    tiktok: "@alextok",
    category: "Fashion",
  },
];
