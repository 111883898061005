import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import Dashboard from "./components/Admin/Dashboard";
import Layout from "./components/Admin/Layout";
import Influencer from "./components/Admin/Influencer";
import Settings from "./components/Admin/Settings";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route für das Registrierungsformular */}
        <Route path="/register" element={<RegisterForm />} />

        {/* Route für die Willkommens-Seite */}
        <Route
          path="/"
          element={
            <div className="text-center mt-20">
              <h1>Willkommen!</h1>
            </div>
          }
        />

        {/* Routes mit Layout */}
        <Route path="/admin" element={<Layout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/influencer" element={<Influencer />} />
          <Route path="/admin/settings" element={<Settings />} />

          {/* Redirect von /admin zu /admin/dashboard */}
          <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
