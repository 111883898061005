import React from "react";
import { Link } from "react-router-dom";

export default function Dashboard() {
  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <div className="bg-white overflow-hidden shadow rounded-lg p-4">
          <p className="text-sm font-medium text-gray-500 truncate">
            User Gesamt
          </p>
          <p className="mt-1 text-3xl font-semibold text-gray-900">100</p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-6">
        <div className="bg-white overflow-hidden shadow rounded-lg p-4">
          <p className="text-sm font-medium text-gray-500 truncate">heute</p>
          <p className="mt-1 text-3xl font-semibold text-gray-900">10</p>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg p-4">
          <p className="text-sm font-medium text-gray-500 truncate">
            letzten 3 Tage
          </p>
          <p className="mt-1 text-3xl font-semibold text-gray-900">25</p>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg p-4">
          <p className="text-sm font-medium text-gray-500 truncate">
            letzte Woche
          </p>
          <p className="mt-1 text-3xl font-semibold text-gray-900">65</p>
        </div>
      </div>
    </div>
  );
}
